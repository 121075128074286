<template>
	<CRow>
		<CCol
			col="12"
			xl="12"
		>
			<CCard>
				<CCardHeader>
					<div class="card-header-actions-title">
						Anagrafica Utenti
					</div>
					<div class="card-header-actions dropdown-actions">
						<CDropdown :caret="false">
							<template v-slot:toggler-content>
								<i class="fas fa-ellipsis-v"></i>
							</template>
							<CButton
								@click="newUserUtilities(loadUsers)"
								color="sdAzure"
								class="px-3"
							>Nuovo <i class="fas fa-user"></i>
							</CButton>
						</CDropdown>
					</div>
				</CCardHeader>
				<CCardBody>
					<CDataTable
						responsive
						hover
						striped
						:items="usersList"
						:fields="fields"
						:tableFilter="{label: 'Filtra', placeholder: 'Cerca...', external: true, lazy: true}"
						:itemsPerPageSelect="{label: 'Elementi per pagina', external: true, values: [20, 50, 100]}"
						clickable-rows
						@row-clicked="rowClicked"
						@update:table-filter-value="tableFilterValue"
						@pagination-change="paginationChange"
						:itemsPerPage="itemsPerPage"
						:pagination="false"
						:noItemsView="{ noResults: 'Non ci sono risultati', noItems: 'Non ci sono elementi' }"
						:loading="isLoading"
					>
						<template #actions="data">
							<td>
								<div class="table-actions dropdown-actions">
									<CDropdown :caret="false">
										<template v-slot:toggler-content>
											<i class="fas fa-ellipsis-v"></i>
										</template>
										<CButton
											@click="rowClicked(data.item, undefined, undefined)"
											color="dark"
											class="px-3"
										><span class="button-label-xs">Dettaglio</span> <i class="fas fa-eye"></i>
										</CButton>
										<CButton
											@click="viewUser(data.item.id)"
											color="sdAzure "
											class="px-3 last"
										><span class="button-label-xs">Modifica</span> <i class="fas fa-pencil"></i>
										</CButton>
										<CButton
											@click="deleteUser(data.item)"
											color="sdRed"
											class="px-3 last"
										><span class="button-label-xs">Cancella</span> <i class="fas fa-trash"></i>
										</CButton>
									</CDropdown>
								</div>
							</td>
						</template>
						<template #createdDate="data">
							<td>
								{{DateTime.fromISO(data.item.createdDate).setLocale('it').toLocaleString(DateTime.DATETIME_FULL)}}
							</td>
						</template>
					</CDataTable>
					<CPagination
						:activePage.sync="currentPage"
						:pages="pages"
						@update:activePage="activePageChange"
						size="lg"
						align="center"
					/>
				</CCardBody>
			</CCard>
		</CCol>
	</CRow>
</template>

<script>
import { cilDelete, cilContact } from '@coreui/icons'
import { mapActions, mapMutations } from "vuex";
import { getDeleteUserModalOptions, pushToast, newUserUtilities } from "../../utilities/utilities";
import { DateTime } from "luxon";

export default {
	name: 'Utenti',
	setup() {
		return { DateTime: DateTime };
	},
	created() {
		this.loadUsers();
	},
	data() {
		return {
			itemsPerPage: 20,
			deleteIcon: cilDelete,
			viewIcon: cilContact,
			fields: [
				{ key: 'firstName', label: 'Nome' },
				{ key: 'lastName', label: 'Cognome' },
				{ key: 'email', label: 'Email' },
				{ key: 'createdDate', label: 'Data creazione' },
				{ key: 'actions', label: 'Azioni', sorter: false, filter: false, _classes: ['actions-column'] }
			],
			usersList: undefined,
			pagination: undefined,
			total: undefined,
			pages: undefined,
			currentPage: undefined
		}
	},
	methods: {
		...mapActions(["getUsers", "deleteUser"]),
		...mapMutations(["setupModalOptions", "setupSubheaderButton"]),
		rowClicked(item, index, column) {
			column !== "actions" && this.$router.push({ path: `utenti/${item.id}` });
		},
		deleteUser(data) {
			this.$store.commit('setupModalOptions', getDeleteUserModalOptions(data.firstName, data.lastName, (action) => {
				if (action === 1) {
					this.$store.dispatch('deleteUser', data.id).then(x => {
						this.pushToast({ title: 'Cancellato', body: 'Utente cancellato', color: 'success' });
						this.loadUsers();
					});
				}
			}));
		},
		viewUser(id) {
			this.$store.commit('setupModalOptions', {
				addContentClasses: 'modal-footer-hidden', title: 'Modifica utente', component: "Utente", params: {
					id: id, callback: () => {
						this.currentPage = 1;
						this.loadUsers();
					}
				}
			});
		},
		tableFilterValue(value) {
			if (value === "" || value.length >= 3) {
				this.currentPage = 1;
				this.searchTerm = value;
				this.loadUsers();
			} else {
				this.pushToast({ title: 'Attenzione', body: 'Il filtro deve contenere almeno 3 caratteri', color: 'danger' });
			}
		},
		paginationChange(value) {
			this.itemsPerPage = value;
			this.loadUsers();
		},
		activePageChange(value) {
			this.currentPage = value;
			this.loadUsers();
		},
		loadUsers() {
			this.isLoading = true;
			this.$store.dispatch("getUsers", {
				perPage: this.itemsPerPage,
				currentPage: this.currentPage || 1,
				searchTerm: this.searchTerm
			}).then(x => {
				const pagination = x.pagination;
				this.usersList = x.data;
				this.total = pagination.hasOwnProperty('total') && pagination.total || this.total;
				const result = this.total && (Math.ceil(this.total / this.itemsPerPage));
				this.pages = typeof result === 'number' ? result : 1;
				this.isLoading = false;
			});
		},
		pushToast,
		newUserUtilities
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.table-actions {
	// text-align: right;
	& > button {
		&:not(:last-of-type) {
			margin-right: 1rem;
			// margin-bottom: 0.65rem;
		}
	}
}
</style>